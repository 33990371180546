<template>
  <div class="goods-list">
    <div v-for="item in coupon.data" :key="item.cid" class="left-content" @click="goGoodsInof(item)">
      <van-image class="left-img" :src="item.couponImg" />
      <div class="left-text">
        <div class="left-text-center">
          <div class="price">￥<span class="price-num" v-text="item.usedMoney" /></div>
          <div class="clues van-ellipsis" v-text="item.couponName" />
          <div class="full-reduction"><span v-text="item.remind" /><span v-text="item.usedRemind" /></div>
        </div>
        <van-button class="left-button" type="danger" round color="#F7263C" @click.stop="postVouchers(item)">{{ item.couponState | couponStateText }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Image, Button } from 'vant'
Vue.use(Image).use(Button)
import { postVouchers } from '@/services/pay'
export default {
  name: 'GoodsList',
  filters: {
    couponStateText(val) {
      switch (val) {
        case 0:
          return '立即领取'
        case 2:
          return '前往使用'
      }
    }
  },
  props: { coupon: { type: Object, default: null }},
  methods: {
    /**
     * @description: 领取优惠券
     * @Date: 2021-07-31 11:28:28
     * @author: 旭日
     */
    async postVouchers(item) {
      if (item.couponState === 0) {
        const params = {
          cid: item.cid,
          uid: localStorage.getItem('uid')
        }
        const { code } = await postVouchers(params)
        if (code === 200) {
          this.$parent.$parent.getRecommend()
        }
      } else {
        this.goGoodsInof(item)
      }
    },
    /**
     * @description: 去商品详情页
     * @Date: 2021-07-31 11:20:20
     * @author: 旭日
     * @param {*} item 商品信息
     */
    goGoodsInof(item) {
      this.$router.push({
        path: '/goodsDetailZY',
        query: {
          goodsId: item.ProductId,
          skuid: item.skuId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.goods-list{
  text-align: left;
  .left-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    background: #fff;
    border-radius: 5px;
    .left-img{
      min-width: 67px;
      width: 67px;
      height: 67px;
    }
    .left-text{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left-text-center{
        margin: 0 10px 0 30px;
        width: 142px;
        .price{
          font-weight: 500;
          font-size: 13px;
          color: #F7263C;
          .price-num{
            font-size: 26px;
          }
        }
        .clues{
          font-size: 13px;
          color: #333;
          margin: 5px 0;
        }
        .full-reduction{
          color: #999;
          white-space:nowrap;
          span{
            display: inline-block;
            white-space:nowrap;
            transform:scale(0.9);
            font-size: 10px;
            margin-left: -4px;
          }
        }
      }
      .left-button{
        font-size: 14px;
        width: 75px;
        height: 35px;
        padding: 0;
      }
    }
  }
}

</style>
