<template>
  <div class="pay-query">
    <div class="pay-query-padding">
      <van-image :src="statusIcon" round class="pay-query-img" />
      <div class="pay-query-title">{{ statusTitle }}</div>
      <div v-if="statusType===2" class="pay-query-prompt" v-text="pollingOrder.noticeMessage" />
      <div v-if="statusType===1" class="pay-query-order"><span>支付金额：</span><span v-text="pollingOrder.money" /></div>
      <div v-if="statusType===1" class="pay-query-order"><span>优惠金额：</span><span v-text="pollingOrder.salePrice" /></div>
      <div v-if="statusType!==2" class="pay-query-order"><span>订单编号：</span><span v-text="orderNo" /></div>
      <div v-if="statusType===1" class="pay-query-order"><span>支付时间：</span><span v-text="pollingOrder.payTime" /></div>
      <div v-if="isLife !== '1' && statusType!==0" class="pay-query-buttons">
        <van-button class="pay-query-button" type="default" round text="查看订单" @click="goCopnlist" />
        <van-button v-if="statusType===1" class="pay-query-button" type="danger" round color="#F7263C" text="返回首页" @click="postBuyAgainAddCart()" />
        <van-button v-if="statusType===2" class="pay-query-button" type="danger" round color="#F7263C" text="重新支付" @click="repay()" />
      </div>
      <Swiper v-if="temp.banner" :banner="temp.banner" />
    </div>
    <TabList v-if="temp.banner && statusType===1" :list="temp" />
  </div>
</template>

<script>
import Vue from 'vue'
import { Image, Button } from 'vant'
Vue.use(Image).use(Button)
import Swiper from './component/Swiper'
import TabList from './component/TabList.vue'
import {
  getRecommend,
  getPayOrderStatus
  // postBuyAgainAddCart
} from '@/services/pay'
import { paySuccess } from '@/services/activity/admire-life'
export default {
  name: 'PayConsequent',
  components: { Swiper, TabList },
  data() {
    return {
      temp: {}, // 推荐商品
      status: '支付结果查询中···', // 状态文本
      statusImg: require('@/assets/images/timeout@2x.png'), // 状态图片
      orderNo: '', // 订单号
      pollingOrder: {}, // 订单状态数据
      pollingTimes: 0, // 轮询迭代次数
      polling: null, // 轮询延迟函数
      isLife: null, // 是否仰生活
      type: 1,
      payStatus: 0
    }
  },
  computed: {
    statusType() {
      return this.payStatus
    },
    statusTitle() {
      let statusTitle = ''
      switch (this.payStatus) {
        case 1:
          statusTitle = '支付成功'
          break
        case 2:
          statusTitle = '您的订单支付失败了'
          break
        default:
          statusTitle = '支付结果查询中···'
          break
      }
      return statusTitle
    },
    statusIcon() {
      let statusIcon = ''
      switch (this.payStatus) {
        case 1:
          statusIcon = require('@/assets/images/dui2.png')
          break
        case 2:
          statusIcon = require('@/assets/images/fails.png')
          break
        default:
          statusIcon = require('@/assets/images/timeout@2x.png')
          break
      }
      return statusIcon
    }
  },
  created() {
    const { orderNo, isLife } = this.$route.query
    this.orderNo = orderNo
    this.isLife = isLife
    // 如果是仰生活则不需要推荐信息
    if (!(isLife && isLife === '1')) {
      this.getRecommend()
    }
    this.getPayOrderStatus()
  },
  beforeDestroy() {
    if (this.polling) {
      clearTimeout(this.polling)
    }
    this.polling = null
  },
  methods: {
    /**
     * @description: 重新支付
     * @Date: 2021-08-03 14:28:44
     * @author: 旭日
     */
    repay() {
      const { goodsId, orderNo } = this.$route.query
      this.$router.push({
        path: '/pay',
        query: {
          orderNo,
          goodsId
        }
      })
    },
    /**
     * @description: 返回首页 //再来一单
     * @Date: 2021-08-03 14:27:45
     * @author: 旭日
     */
    async postBuyAgainAddCart() {
      // const params = {
      //   uid: window.localStorage.getItem('uid'),
      //   ordersn: this.orderNo,
      //   positionFrom: '0'
      // }
      // const { code } = await postBuyAgainAddCart(params)
      // if (code === 200) {
      //   this.$router.push({
      //     path: '/shopCar',
      //     query: {
      //       activityId: ''
      //     }
      //   })
      // }
      this.$router.push({
        path: '/'
      })
    },
    /**
     * @description: 订单详情
     * @Date: 2021-07-30 19:15:40
     * @author: 旭日
     */
    goCopnlist() {
      this.$router.push({ path: '/Orderdetails', query: { orderNo: this.pollingOrder.orderSnSon }})
    },
    /**
     * @description: 轮询查询结果
     * @Date: 2021-07-30 16:35:28
     * @author: 旭日
     */
    async getPayOrderStatus() {
      try {
        if (this.polling) {
          clearTimeout(this.polling)
        }
        const { isLife } = this.$route.query
        const { code, data } = await getPayOrderStatus({ orderNo: this.orderNo, type: this.type })
        if (code === 200) {
          const { timeCount, count, payStatus, orderNo } = data
          this.pollingOrder = data
          this.orderNo = orderNo
          this.payStatus = payStatus
          if (this.pollingTimes < count) {
            this.pollingTimes++
            if (payStatus === 0) {
              this.polling = setTimeout(() => {
                this.getPayOrderStatus()
              }, timeCount)
            } else {
              // 仰生活回调函数
              isLife === '1' ? paySuccess(true) : ''
            }
          } else {
            this.payStatus = 2
            // 仰生活回调函数
            isLife === '1' ? paySuccess(false) : ''
          }
        }
      } catch (e) {
        if (this.pollingTimes < 30) {
          this.pollingTimes++
          this.polling = setTimeout(() => {
            this.getPayOrderStatus()
          }, 2000)
        } else {
          this.payStatus = 2
        }
      }
    },
    /**
     * @description: 获得推荐列表
     * @Date: 2021-07-30 15:32:19
     * @author: 旭日
     */
    async getRecommend() {
      const { goodsId, orderNo } = this.$route.query
      const params = {
        goodsId,
        orderNo,
        uid: window.localStorage.getItem('uid')
      }
      const { code, data } = await getRecommend(params)
      if (code === 200) {
        this.temp = data
      }
    }
  }
}
</script>

<style lang="less" scoped>
*{
  touch-action: pan-y;
}
.pay-query{
  .pay-nav-bar{
    /deep/ .van-icon {
      color: #000;
    }
  }
  .pay-query-padding{
    padding: 0 15px 20px;
    .pay-query-img{
      width: 44px;
      height: 44px;
      margin: 40px 0 10px;
    }
    .pay-query-title{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #333;
      margin-bottom: 10px;
    }
    .pay-query-prompt{
      font-size: 13px;
      line-height: 18px;
      color: #999;
      margin: 0 50px;
    }
    .pay-query-order{
      display: flex;
      justify-content: space-between;
      font-size: 13px;
      margin-top: 30px;
      margin-bottom: 15px;
    }
    .pay-query-buttons{
      display: flex;
      justify-content: space-evenly;
      font-size: 13px;
      margin: 20px 0;
      .pay-query-button{
        width: 120px;
      }
    }
  }
}
</style>
