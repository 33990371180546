<template>
  <div class="tab-list">
    <div class="tab-list-shell">
      <van-tabbar v-model="active" class="tab" active-color="#F7263C" inactive-color="#333333" :fixed="false" :border="false" @change="onChange">
        <van-tabbar-item v-for="item in title" :key="item">
          <div class="tab-title" v-text="item" />
        </van-tabbar-item>
      </van-tabbar>
      <GoodsList v-if="active===0" :coupon="list.coupon" />
    </div>
    <GoodsFalls v-if="active===1" :goods="list.goods" />
  </div>
</template>

<script>
import Vue from 'vue'
import { Tabbar, TabbarItem } from 'vant'
Vue.use(Tabbar).use(TabbarItem)
import GoodsList from './component/GoodsList.vue'
import GoodsFalls from './component/GoodsFalls.vue'
export default {
  name: 'TabList',
  components: { GoodsList, GoodsFalls },
  props: { list: { type: Object, default: null }},
  data() {
    return {
      active: 0,
      title: ['领券有礼', '为您推荐']
    }
  },
  methods: {
    onChange(index) {
      this.active = index
    }
  }
}
</script>

<style lang="less" scoped>
.tab-list{
  background: #F8F8F8;
  padding-bottom: 20px;
  .tab-list-shell{
    padding: 0 15px;
  }
  .tab{
    margin-bottom: 15px;
  }
  /deep/ .van-tabbar{
    background: none;
    .tab-title{
      position: relative;
      font-size: 14px;
    }
  }
  /deep/ .van-tabbar-item--active{
    background: none;
    .tab-title{
      &::before{
        content: "";
        width: 20px;
        height: 6px;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        background: url("~@/assets/images/tabIndex.png") no-repeat;
        background-size: 100% 100%;
        bottom: -15px;
      }
    }
  }
}
</style>
