import request from '@/services/request'

/** *****
 * @description: 支付接口
 * @Date: 2021-07-29 19:18:17
 * @author: 旭日
 */
export function getPayModule(data) {
  return request({
    url: '/app/pay/getPayModule',
    method: 'post',
    data
  })
}
export function getNewPayParams(data) {
  return request({
    url: '/app/nl/getNewPayParams',
    method: 'post',
    data
  })
}
/** *****
 * @description: 获取支付成功推荐接口
 * @Date: 2021-07-30 10:02:24
 * @author: 旭日
 */
export function getRecommend(data) {
  return request({
    url: '/product/getRecommend',
    method: 'post',
    data
  })
}

/** *****
 * @description: 支付接口
 * @Date: 2021-07-30 14:40:21
 * @author: 旭日
 */
export function getPayParams(data) {
  return request({
    url: '/app/pay/getPayParams',
    method: 'post',
    data
  })
}

/** *****
 * @description: 订单结果查询
 * @Date: 2021-07-30 16:28:33
 * @author: 旭日
 */
export function getPayOrderStatus(data) {
  return request({
    url: '/app/member/order/getPayOrderStatus',
    method: 'post',
    data,
    headers: {
      hideLoading: true
    }
  })
}

/** *****
 * @description:  领取优惠券
 * @Date: 2021-07-31 11:25:57
 * @author: 旭日
 */
export function postVouchers(data) {
  return request({
    url: '/mall/sendCoupon',
    method: 'post',
    data
  })
}

/** *****
 * @description: 再来一单
 * @Date: 2021-07-31 11:50:25
 * @author: 旭日
 */
export function postBuyAgainAddCart(data) {
  return request({
    url: '/product/buyAgainAddCart',
    method: 'post',
    data
  })
}
