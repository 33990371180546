<template>
  <div>
    <van-grid :column-num="2" :gutter="11" :border="false" class="goods-list">
      <van-grid-item v-for="item in goods.data" :key="item.goodId" class="goods-list-item" @click="goGoodsInof(item)">
        <div class="item-content">
          <van-image :src="item.goodCover" class="goods-img" radius="7px 7px 0 0" />
          <div class="content-text">
            <div class="text-title van-multi-ellipsis--l2" v-text="item.goodName" />
            <div class="text-price">
              <span class="price-vip">￥<span class="price-vip-num" v-text="item.goodPrice" /></span>
              <span class="price">￥<span v-text="item.goodVipPrice" /></span>
            </div>
          </div>
        </div>
      </van-grid-item>
    </van-grid>
    <div class="prompt">没有更多了哦~</div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Grid, GridItem, Image } from 'vant'
Vue.use(Grid).use(GridItem).use(Image)

export default {
  name: 'GoodsFalls',
  props: { goods: { type: Object, default: null }},
  methods: {
    /**
     * @description: 去商品详情页
     * @Date: 2021-07-31 11:20:20
     * @author: 旭日
     * @param {*} item 商品信息
     */
    goGoodsInof(item) {
      this.$router.push({
        path: '/goodsDetailZY',
        query: {
          goodsId: item.goodId,
          skuid: item.skuId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.goods-list{
  /deep/ .van-grid-item__content{
    padding: 0;
    border-radius: 7px;
  }
  .goods-list-item{
    .item-content{
      text-align: left;
      .goods-img{
        width: 170px;
        height: 170px;
      }
      .content-text{
        padding: 5px;
        .text-title{
          color: #333;
          line-height: 20px;
          font-size: 14px;
        }
        .text-price{
          font-size: 12px;
          margin: 15px 0 5px;
          .price-vip{
            color: #F7263C;
            margin-right: 5px;
            font-weight: 500;
            .price-vip-num{
              font-size: 16px;
              margin-left: -1px;
            }
          }
          .price{
            color: #999;
            text-decoration: line-through;
          }
        }
      }
    }
  }
}
.prompt{
  margin: 15px auto 0;
  font-size: 13px;
  color: #999;
}
</style>
